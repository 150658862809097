import React from 'react';
import styles from './style.module.scss';
import { buildClassName } from 'utils/build-class-name';

interface Props {
  className?: string;
  content: string;
  isActive: boolean;
}

export default function Tooltip({ className, content, isActive }: Props) {
  return (
    <div className={buildClassName(className, styles.component, isActive && styles.active)}>
      {content}
    </div>
  );
}
